*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Caveat Brush', cursive;
    background-color: #464970;
    overflow-x: hidden;
}

.navbar-brand {
    font-size: 70px;
    text-transform: uppercase;
    width: 80%;
    text-align: center;
    white-space: normal;
    line-height: 1;
}

nav {
    height: 150px;
}

.logo {
    position: absolute;
    top: 30px;
    left: 30px;
    height: 70px;
}

.to-stack {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 65px;
}

.search-card-body {
    background-color: #86E2F7;
    border: none;
}

.search-icon {
    width: 40px;
    height: 40px;
}

.search-btn {
    background-color: #2D95FF;
    outline: none;
    transition: background-color .3s ease-in-out;
}

.search-btn:hover {
    background-color: #2682dd;
}

.jumbo-1 {
    background-color: #F4DA58;
}

.jumbo-2 {
    background-color: #F68381;
}

.jumbo-3 {
    background-color: #4EF09C;
}

.arrow-down {
    transform:
        rotateY(180deg)
        rotate(-90deg);
    height: 5vh;
    width: 5vh;
    position: relative;
    top: 15px;
    left: 5px;
}

.color-M {
    color: #F4DA58;
}

.color-G {
    color: #F68381;
}

.color-B {
    color: #4EF09C;
}

.card-movie {
    border: 3px solid #F4DA58;
}

.card-game {
    border: 3px solid #F68381;
}

.card-book {
    border: 3px solid #4EF09C;
}

.card-header-medium {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.card-body-medium {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn-icon {
    width: 30px;
    height: 30px;
}

.details-btn {
    background-color: #86E2F7;
    font-size: 1.3rem;
    transition: background-color .3s ease-in-out;
}

.details-btn:hover {
    background-color: rgb(77, 160, 228);
}

.add-movie-btn {
    background-color: #F4DA58;
    font-size: 1.3rem;
    transition: background-color .3s ease-in-out;
}

.add-movie-btn:hover {
    background-color: rgb(245, 211, 41);
}

.add-game-btn, .trash-medium-btn {
    background-color: #F68381;
    font-size: 1.3rem;
    transition: background-color .3s ease-in-out;
}

.add-game-btn:hover, .trash-medium-btn:hover {
    background-color: rgb(238, 77, 75);
}

.add-book-btn, .check-medium-btn {
    background-color: #4EF09C;
    font-size: 1.3rem;
    transition: background-color .3s ease-in-out;
}

.add-book-btn:hover, .check-medium-btn:hover {
    background-color: rgb(10, 218, 110);
}

/* DETAILS PAGE */

.add-btn-details {
    font-size: 1.5rem;
}

/* Movie */

.card-header-movie {
    background-color: #F4DA58;
    list-style-type: none;
}

.card-movie .list-group-item {
    border-bottom: 1px solid #F4DA58;
}

.card-movie .list-group-item, .card-movie .card-text {
    font-size: 1.3rem;
}

.card-movie .list-group-item strong {
    font-size: 1.4rem;
}

.btn-icon-details, .go-back-arrow {
    width: 35px;
    height: 35px;
}

.btn-go-back {
    background-color: #86E2F7;
    transition: background-color .3s ease-in-out;
    font-size: 1.5rem;
}

.btn-go-back:hover {
    background-color: rgb(77, 160, 228);
}

/* Game */

.card-header-game {
    background-color: #F68381;
    list-style-type: none;
}

.card-game .list-group-item {
    border-bottom: 1px solid #F68381;
}

.card-game .list-group-item, .card-game .card-text {
    font-size: 1.3rem;
}

.card-game .list-group-item strong {
    font-size: 1.4rem;
}

/* Book */

.card-header-book {
    background-color: #4EF09C;
    list-style-type: none;
}

.card-book .list-group-item {
    border-bottom: 1px solid #4EF09C;
}

.card-book .list-group-item, .card-book .card-text {
    font-size: 1.3rem;
}

.card-book .list-group-item strong {
    font-size: 1.4rem;
}

/* Stack */

.date-added {
    position: absolute;
    bottom: -20px;
    right: 5px;
}

/* Progress Bars */

.progress {
    height: 25px;
}

.progress-movies {
    background-color: rgb(244, 218, 88, .4);
}

.progress-games {
    background-color: rgb(246, 131, 129, .4);
}

.progress-books {
    background-color: rgb(78, 240, 156, .4);
}

.progress-text {
    color: #000;
}

.progress-bar-movies {
    background-color: #F4DA58;
}

.progress-bar-games {
    background-color: #F68381;
}

.progress-bar-books {
    background-color: #4EF09C;
}

@media (max-width: 768px){

    .navbar-brand {
        font-size: 60px;
    }

    .logo {
        height: 60px;
        top: 20px;
        left: 20px;
    }

    .to-stack {
        height: 60px;
        top: 20px;
        right: 20px;
    }
}

@media (max-width: 480px) {

    .navbar-brand {
        font-size: 50px;
    }

    .logo {
        height: 45px;
        top: 10px;
        left: 10px;
    }

    .to-stack {
        height: 45px;
        top: 10px;
        right: 10px;
    }
}